<template>
  <v-app>
    <div class="background">
      <div class="row">
        <div class="col-12 header">
          <div class="row">
            <div class="col-4">
              <a href="https://stafftimerapp.com/" target="_blank">
                <img
                  class="d-block logo-sticky w-10 logo-bold"
                  src="../../assets/images/st_logo_new.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <v-col cols="12" sm="4" offset-md="4">
          <v-form
            @reset="onReset"
            @submit="onSubmit"
            v-model="valid"
            ref="account_form"
          >
            <div class="row unset-margin" style="justify-content: center">
              <div class="col-md-12 unset-padding col-lg-12 col-xl-12 img-col">
                <div v-if="startCam">
                  <div class="start-camera text-center">
                    <div>
                      <web-cam
                        :deviceId="deviceId"
                        @cameras="onCameras"
                        @error="onError"
                        height="250px"
                        ref="webcam"
                        width="100%"
                      />
                    </div>
                    <div class="row">
                      <div
                        class="col-md-12 unset-padding"
                        style="padding-bottom: 25px"
                      >
                        <button
                          @click="onCapture"
                          class="my-btn-img text-center mt-2"
                          type="button"
                        >
                          Capture Photo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row ml-0 mt-3 align-items-center displayImage text-center"
                  v-if="!startCam"
                >
                  <div class="text-center img-container" v-if="!form.image_src">
                    <label class="fileContainer">
                      <v-gravatar
                        class="grav"
                        :email="form.name"
                        alt="Nobody"
                        :size="150"
                        default-img="retro"
                      />
                      <div class="overlay">
                        <p
                          title="Please upload picture of 150x150 in dimensions and of max size 1MB"
                        >
                          Upload an image
                        </p>
                        <br />
                        <i class="fa fa-camera" aria-hidden="true"></i>
                      </div>
                      <input
                        @change="onFileChange"
                        accept="image/png, image/jpg, image/jpeg"
                        data-vv-as="Image"
                        name="logo"
                        type="file"
                      />
                    </label>
                    <button
                      @click="openWebCam"
                      class="my-btn-img text-center mt-3"
                      type="button"
                    >
                      Upload From WebCam
                    </button>
                  </div>
                  <div v-else>
                    <div
                      class="spinner-border text-primary spinner-set"
                      role="status"
                      v-if="showSpinner && cropped_file_url"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <img
                      :src="cropped_file_url"
                      @error="
                        $event.target.src = require('@/assets/images/no-member.svg')
                      "
                      class="user-image"
                      v-bind:class="{ blur: showSpinner }"
                      v-if="
                        cropped_file_url &&
                        (uploadResult === 'Please wait...!' ||
                          uploadResult === true ||
                          uploadResult === '')
                      "
                    />
                    <img
                      class="success-tick"
                      height="30"
                      src="../../assets/images/success-image.svg"
                      v-if="
                        uploadResult === true &&
                        (uploadResult === 'Please wait...!' ||
                          uploadResult === true ||
                          uploadResult === '')
                      "
                      width="30"
                    />
                    <div
                      class="margin-button"
                      v-if="
                        is_cropped === true &&
                        (uploadResult === 'Please wait...!' ||
                          uploadResult === true ||
                          uploadResult === '')
                      "
                    >
                      <button
                        @click="removeImage"
                        class="my-btn-img text-center mt-3"
                        type="button"
                      >
                        Change Image
                      </button>
                    </div>
                    <div
                      class="button-margin"
                      v-bind:class="{ top: uploadResult }"
                      v-if="
                        (is_cropped === false && !cropped_file_url) ||
                        (uploadResult &&
                          uploadResult !== 'Please wait...!' &&
                          uploadResult !== true)
                      "
                    >
                      <button
                        @click="removeImage"
                        class="my-btn-img button-width text-center mt-3 ml-3"
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                    <div v-if="cropped_file_url && is_cropped === false">
                      <button
                        @click="removeImage"
                        class="my-btn-img text-center mt-3"
                        type="button"
                      >
                        Change Image
                      </button>
                    </div>
                    <imageCroper
                      :cropHeight="150"
                      :cropWidth="150"
                      :imgSrc="form.image_src"
                      :imgStyle="{ width: '250px', height: '250px' }"
                      :resizeable="false"
                      @croped_file="attach_crop_file($event)"
                      alt="User Image"
                      v-if="
                        !cropped_file_url ||
                        (uploadResult &&
                          uploadResult !== 'Please wait...!' &&
                          uploadResult !== true)
                      "
                    />
                  </div>
                  <div
                    class="text-center"
                    v-bind:class="{
                      error:
                        uploadResult &&
                        uploadResult !== 'Please wait...!' &&
                        uploadResult !== true,
                    }"
                  >
                    <span class="error-msg ml-2" v-if="uploadResult !== true">{{
                      uploadResult
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 style-top float-left">
              <div id="login_form">
                <!--                        <div class="row unset-margin info-spacing">-->
                <div class="row pt-5">
                  <div class="col-10 offset-1">
                    <v-text-field
                      outlined
                      @blur="keyTest"
                      name="name"
                      label="Name"
                      type="text"
                      :rules="required"
                      v-model="form.name"
                    ></v-text-field>
                    <v-text-field
                      outlined
                      id="passwordReset"
                      ref="password"
                      name="password"
                      label="Set Password"
                      type="text"
                      required
                      :rules="rules"
                      v-model="form.password"
                      :append-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="visible ? 'text' : 'password'"
                      @click:append="visible = !visible"
                      autocomplete="off"
                    ></v-text-field>
                    <v-text-field
                      outlined
                      id="passwordConfirm"
                      name="password_confirmation"
                      label="Confirm Password"
                      type="text"
                      :rules="rules"
                      required
                      v-model="form.repeatPassword"
                      :append-icon="visible_confirm ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="visible_confirm ? 'text' : 'password'"
                      @click:append="visible_confirm = !visible_confirm"
                      autocomplete="off"
                    ></v-text-field>
                    <v-btn
                      color="#2758f6"
                      width="100%"
                      height="17%"
                      ref="submit"
                      type="submit"
                      class="white--text text x-large"
                      :disabled="!valid"
                    >
                      <v-progress-circular
                        indeterminate
                        v-if="loginLoader"
                        size="20"
                      >
                      </v-progress-circular>
                      Finish
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </v-col>
      </div>
      <!--        <div class="members-login-area d-flex">-->
      <!--            <div class="col-md-7 col-xl-6 m-auto login_form float-left">-->
      <!--                    -->
      <!--            </div>-->
      <!--        </div>-->

      <div class="row">
        <div class="col-12 footer-image">
          <img
            width="100%"
            height="150px"
            src="../../assets/images/Light_bg.png"
          />
        </div>
      </div>
    </div>
    <Snackbar
      :snackbar.sync="snackbar"
      :message="message"
      :color="snackbarColor"
    />
  </v-app>
</template>

<script>
import { required } from "@/Constants/Const";
import { WebCam } from "vue-web-cam";
import { find, head } from "lodash";
import Vue from "vue";
import Gravatar from "vue-gravatar";
import MD5 from "crypto-js/md5";
import Snackbar from "@/components/includes/Snackbar";
import Cookie from "js-cookie";

Vue.component("v-gravatar", Gravatar);
export default {
  name: "EmailInviteMemberSetPassword",
  components: {
    WebCam,
    Snackbar,
    imageCroper: () => import("@/components/includes/helper/ImageCropper"),
  },
  data() {
    return {
      valid: true,
      loginLoader: false,
      rules: [
        (value) => !!value || "Required.",
        (value) => (value || "").length > 5 || "Max 6 characters",
      ],
      message: "",
      snackbar: false,
      snackbarColor: "",
      required: required,
      visible_confirm: false,
      visible: false,
      gav_img_url: "",
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      has_special_number: false,
      has_lowercase_uppercase: false,
      check_length_password: false,
      login_button: true,
      error_data: false,
      error_data_name: false,
      error_data_image: false,
      is_cropped: false,
      cropped_file_url: "",
      eyeSlashReset: false,
      showSpinner: false,
      eyeReset: true,
      eyeSlashConfirm: false,
      eyeConfirm: true,
      camera: null,
      deviceId: null,
      devices: [],
      startCam: false,
      captureImage: false,
      uploadResult: "",
      form: {
        name: "",
        password: "",
        repeatPassword: "",
        image_src: "",
        email: "",
      },
    };
  },
  computed: {
    device: function () {
      return find(this.devices, (n) => n.deviceId == this.deviceId);
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      let first = head(this.devices);
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
  mounted() {
    document.cookie = "XSRF-TOKEN= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    Cookie.remove("XSRF-TOKEN", {
      path: "/",
      domain: ".stafftimerapp.com",
      sameSite: "lax",
    });
    Cookie.remove("stafftimerapp_session", {
      path: "/",
      domain: ".stafftimerapp.com",
      sameSite: "lax",
    });
    if (this.$store.state.auth.user !== null) {
      this.$store
        .dispatch("custom/user_logout")
        .then(() => {
          this.resetAuthAndState();
        })
        .catch(() => {
          this.resetAuthAndState();
        });
    }
    // let token  = this.$route.params.invite_token;

    let data = {
      email: this.$route.params.email,
    };
    this.$store
      .dispatch("auth/isUserAlreadyVerified", data)
      .then((response) => {
        this.$root.$emit("snack_bar", {
          show: true,
          message: response.data.error_msg,
          snackbarColor: "red",
        });
        this.$router.push({ name: "login" });
        this.$store.commit("auth/toggle_loader", false);
      })
      .catch(() => {
        this.$store.commit("auth/toggle_loader", false);
      });
    this.check_user();
  },
  methods: {
    resetAuthAndState() {
      this.$store.commit("custom/resetState");
      this.$store.dispatch("auth/reset");
    },
    check_user() {
      if (this.$route.query.first === "first_time") {
        this.new = "first_time";
      }
    },
    keyTest() {
      this.gavatorBase64();
    },
    gavatorBase64() {
      let app = this;
      function toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
            callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      }
      let hash = MD5(app.form.name).toString();
      toDataURL(
        `https://www.gravatar.com/avatar/${hash}?s=80&d=retro&r=g`,
        function (dataUrl) {
          app.gav_img_url = dataUrl;
        }
      );
    },
    password_check: function () {
      this.has_number = /\d/.test(this.form.password);
      this.has_lowercase = /[a-z]/.test(this.form.password);
      this.has_uppercase = /[A-Z]/.test(this.form.password);
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.form.password);
      this.has_number = this.has_number === true;
      this.has_special = this.has_special === true;
      this.has_lowercase = this.has_lowercase === true;
      this.has_uppercase = this.has_uppercase === true;
      this.check_length_password = this.form.password.length >= 8;
      if (
        this.has_number === true &&
        this.has_special === true &&
        this.has_lowercase === true &&
        this.has_uppercase === true &&
        this.form.password.length >= 8
      ) {
        this.login_button = false;
      } else if (
        this.has_number === false ||
        this.has_special === false ||
        this.has_lowercase === false ||
        this.has_uppercase === false ||
        this.form.password.length < 8
      ) {
        this.login_button = true;
      }
    },
    isImageValid(imageBase64) {
      if (!this.$socket.connected) {
        this.showSpinner = false;
        this.uploadResult =
          "Oops! There was a connectivity issue. Please try again in few moments.";
        this.cropped_file_url = null;
      } else {
        let parts = imageBase64.split(";");
        let FaceApiBase64 = parts[1].split(",")[1];

        return this.$socket.emit("is_image_valid", FaceApiBase64, (data) => {
          this.uploadResult = data;
          this.showSpinner = false;
        });
      }
    },
    show_hide_Password(val) {
      if (val === "Confirm") {
        if (document.getElementById("passwordConfirm").type === "password") {
          this.eyeConfirm = false;
          this.eyeSlashConfirm = true;
          document.getElementById("passwordConfirm").type = "text";
        } else {
          this.eyeSlashConfirm = false;
          this.eyeConfirm = true;
          document.getElementById("passwordConfirm").type = "password";
        }
      } else {
        if (document.getElementById("passwordReset").type === "password") {
          this.eyeReset = false;
          this.eyeSlashReset = true;
          document.getElementById("passwordReset").type = "text";
        } else {
          this.eyeSlashReset = false;
          this.eyeReset = true;
          document.getElementById("passwordReset").type = "password";
        }
      }
    },
    isUserAlreadyVerified() {
      let data = {
        email: this.$route.params.email,
      };
      this.$store
        .dispatch("auth/isUserAlreadyVerified", data)
        .then((response) => {
          this.$root.$emit("snack_bar", {
            show: true,
            message: response.data.error_msg,
            snackbarColor: "red",
          });
          return this.$router.push({ name: "login" });
        });
    },
    onCapture() {
      this.form.image_src = this.$refs.webcam.capture();
      this.onStop();
      this.startCam = false;
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onError() {
      try {
        this.onFileChange();
      } catch (error) {
        this.startCam = false;
      }
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.loginLoader = true;
      //   return;
      let formData = new FormData(this.$refs.save_form);
      let img;
      if (this.cropped_file_url) {
        img = this.cropped_file_url;
      } else {
        img = this.gav_img_url;
      }
      formData.append("name", this.form.name);
      formData.append("image", img);
      formData.append("email", this.$route.params.email);
      formData.append("password", this.form.password);
      formData.append("password_confirmation", this.form.repeatPassword);
      formData.append("email", this.$route.params.email);
      formData.append("token", this.$route.params.token);
      formData.append("notification_id", this.$route.params.notification_id);
      this.$store
        .dispatch("auth/setPassword", formData)
        .then((response) => {
          this.loginLoader = false;
          this.$store.commit("custom/first_time", true);
          this.$store.dispatch("auth/setCurrentUser", response.data);
          this.$router.push({ name: "CompaniesListing" });
        })
        .catch((error) => {
          this.loginLoader = false;
          this.snackbar = true;
          this.snackbarColor = "red";
          if (error.response.data.errors) {
            if (error.response.data.errors.password)
              this.message = error.response.data.errors.password[0];
            if (error.response.data.errors.email)
              console.log("email ", error.response.data.errors.email[0]);
            this.message = error.response.data.errors.email[0];
            if (error.response.data.errors.name)
              this.message = error.response.data.errors.name;
            if (error.response.data.errors.image)
              this.message = error.response.data.errors.image;
          }
        });
      // });
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let _size = file.size;
      if (_size > 1000000) {
        return;
      }
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        vm.form.image_src = e.target.result;
      };
      this.picturePath = file;
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      this.uploadResult = "";
      this.form.image_src = "";
      this.picturePath = "";
      this.cropped_file_url = "";
      this.is_cropped = false;
    },
    attach_crop_file(file_url) {
      let file_path = this.dataURLtoFile(file_url, "user_image");
      this.cropped_file_url = file_url;
      this.picturePath = file_path;
      this.is_cropped = true;
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    openWebCam() {
      this.startCam = true;
      setTimeout(() => this.$refs.webcam.start(), 500);
    },
    onReset(evt) {
      evt.preventDefault();
      this.form.name = "";
      this.form.image_src = "";
      this.form.password = "";
      this.form.confirm_password = "";
    },
  },
};
</script>

<style scoped>
.header {
  z-index: 2 !important;
  height: 69px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(212, 212, 212, 0.5);
}
.footer-image {
  width: 100%;
  margin-top: -3rem;
}
.logo-bold {
  height: 35.54px;
  width: 159px;
  margin-top: 0.4rem;
}
.start-camera video {
  width: 150px;
  height: 150px;
  -o-object-fit: fill;
  object-fit: fill;
  border-radius: 50%;
}

.start-camera h3 {
  font-size: 20px;
  text-align: center;
}
.my-btn-img {
  height: 38px;
  border: 0.75px solid #2758f6;
  border-radius: 3px;
  background-color: rgba(240, 248, 255, 0.93);
  box-shadow: 0 2px 4px 0 rgba(165, 165, 165, 0.23);
  color: #2758f6;
  margin-bottom: 0rem !important;
  font-size: 14px;
  white-space: nowrap;
}

.my-btn-img:hover {
  color: #2758f6;
}
label.fileContainer [type="file"] {
  display: block;
  /*font-size: 999px;*/
  filter: alpha(opacity=0);
  /*min-height: 100%;*/
  /*min-width: 100%;*/
  opacity: 0;
  /*position: absolute;*/
  /*right: 0;*/
  text-align: right;
  /*top: 0;*/
  cursor: pointer;
}

label.fileContainer .img-text {
  margin-top: 39px;
}

label.fileContainer {
  display: block;
  overflow: hidden;
  position: relative;
  font-size: 12px;
  border: dashed 2px #cbdaf2;
  /*padding: 42px 6px;*/
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: rgba(240, 248, 255, 0.93);
  box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.45);
  color: #a2b7d9;
  font-family: "Poppins", "sans-serif";
  letter-spacing: 0.15px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
  background-repeat: no-repeat;
}

label.fileContainer:hover {
  cursor: pointer !important;
  transform: scale(1.02);
}
.grav {
  border-radius: 50%;
  height: 150px;
  margin-top: -10px;
  padding-left: 1px;
  padding-right: 1px;
}
.overlay {
  position: absolute;
  top: 64%;
  background-color: #000000e3 !important;
  color: white;
  font-size: 12px;
  font-weight: bolder;
  padding-top: 10px;
  text-align: center;
  width: 100%;
  height: 100%;
  -webkit-transition: top 0.5s;
  -o-transition: top 0.5s;
  transition: top 0.5s;
}
.displayImage {
  margin: 0 auto;
  display: block;
}
.button-width {
  width: 123px;
}
</style>
